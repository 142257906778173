.taskCard {
    background-color: tomato;
    color: white;
    width: 100%;
    width: 290px;
    box-shadow: 10px 10px black;
    padding: 10px;
    margin: 15px 0;
}

