.button {
    button {
    width: 80px;
    height: 30px;
    background-color: tomato;
    border: none;
    color: white;
    font-weight: 700;
    font-family: "Titillium Web", sans-serif;
    display: inline-block;
    
        &:hover {
            cursor: pointer;
        }
    }
}